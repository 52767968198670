import { render, staticRenderFns } from "./PlayerPointsModal.vue?vue&type=template&id=9f1cf0f8&scoped=true&"
import script from "./PlayerPointsModal.vue?vue&type=script&lang=js&"
export * from "./PlayerPointsModal.vue?vue&type=script&lang=js&"
import style0 from "./PlayerPointsModal.vue?vue&type=style&index=0&id=9f1cf0f8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f1cf0f8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VDialog,VForm,VRow,VSelect,VTextField})
