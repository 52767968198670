<template>
  <v-dialog v-model="show" max-width="700px" @click:outside="close">
    <v-card>
      <div class="modal-container">
        <div>
          <ModalHeader>
            {{ label }}
          </ModalHeader>
          <ModalBody>
            <v-form v-model="isFormValid">
              <v-text-field
                v-model="footballPlayer.name"
                :rules="playerNameRules"
                label="Name"
              ></v-text-field>
              <v-select
                v-model="playerLeague"
                :items="getFootballLeagues"
                :rules="requiredFieldRules"
                item-text="name"
                item-value="id"
                label="League"
              ></v-select>
              <v-select
                v-if="!isNewClub"
                v-model="footballPlayer.club"
                :disabled="!leagueClubs.length"
                :items="leagueClubs"
                :rules="requiredFieldRules"
                label="Club"
              >
                <template v-slot:append-outer>
                  <v-btn color="accent" small @click="isNewClub = true"
                    >New Club
                  </v-btn>
                </template>
              </v-select>
              <v-text-field
                v-if="isNewClub"
                v-model="footballPlayer.club"
                :rules="requiredFieldRules"
                label="New Club"
              >
                <template v-slot:append-outer>
                  <v-btn color="accent" small @click="isNewClub = false"
                    >Old Club
                  </v-btn>
                </template>
              </v-text-field>
              <v-select
                v-model="footballPlayer.position"
                :items="getPositions"
                :rules="requiredFieldRules"
                label="Position"
              ></v-select>
              <v-text-field
                v-if="false"
                v-model="footballPlayer.shirt"
                label="Shirt-slug"
              ></v-text-field>
              <v-text-field
                v-model="footballPlayer.whoscoredId"
                :rules="requiredFieldRules"
                label='"WhoScored" ID'
              ></v-text-field>
            </v-form>
          </ModalBody>
          <ModalFooter>
            <v-row class="mt-5" justify="center">
              <v-btn
                :disabled="!isFormValid"
                class="player-modal-button white--text"
                color="accent"
                large
                min-width="136"
                @click.stop="handleSave()"
                >SAVE
              </v-btn>
              <v-btn
                v-if="footballPlayer.id"
                class="player-modal-button white--text"
                color="red darken-1"
                large
                min-width="136"
                @click.stop="handleDelete()"
                >DELETE
              </v-btn>
              <v-btn
                v-if="footballPlayer.id"
                class="player-modal-button white--text"
                color="lime darken-1"
                large
                min-width="136"
                @click.stop="handelEditPoints()"
                >EDIT POINTS
              </v-btn>
              <v-btn
                class="player-modal-button white--text"
                color="secondary"
                large
                min-width="136"
                @click.stop="close()"
                >CLOSE
              </v-btn>
            </v-row>
          </ModalFooter>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ModalHeader from "./ModalElements/ModalHeader.vue";
import ModalFooter from "./ModalElements/ModalFooter.vue";
import ModalBody from "./ModalElements/ModalBody.vue";
import { FootballPlayer } from "../../../common/entitiesClasses";
import { mapGetters } from "vuex";

export default {
  components: { ModalHeader, ModalBody, ModalFooter },
  data: () => ({
    footballPlayer: {},
    isFormValid: false,
    playerNameRules: [
      (value) => !!value || "Required.",
      (value) => (value || "").length <= 30 || "Max 30 characters",
    ],
    requiredFieldRules: [(value) => !!value || "Required."],
    isNewClub: false,
  }),
  props: {
    value: Boolean,
    passedPlayer: Object,
  },
  methods: {
    close() {
      this.footballPlayer = new FootballPlayer(this.passedPlayer);
      this.$emit("close-modal");
    },
    // TO DO: Form validation!!!!
    handleSave() {
      this.footballPlayer.shirt = "1";
      if (this.label == "EDIT PLAYER") {
        this.$emit("edit-player", this.footballPlayer);
      } else {
        this.$emit("create-player", this.footballPlayer);
      }
      this.close();
    },
    handleDelete() {
      this.$emit("delete-player", this.footballPlayer);
      this.close();
    },
    handelEditPoints() {
      this.$emit("edit-points");
      this.close();
    },
  },
  computed: {
    ...mapGetters("leagues", ["getFootballLeagues"]),
    ...mapGetters("footballPlayers", ["getClubsByLeague"]),

    ...mapGetters(["getPositions"]),
    leagueClubs() {
      return this.footballPlayer.footballLeagueId
        ? Object.keys(
            this.getClubsByLeague(this.footballPlayer.footballLeagueId)
          )
        : [];
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    label() {
      return this.footballPlayer.id ? "EDIT PLAYER" : "CREATE PLAYER";
    },
    playerLeague: {
      get() {
        return this.getFootballLeagues.find(
          ({ id }) => id == this.footballPlayer.footballLeagueId
        );
      },
      set(value) {
        this.footballPlayer.footballLeagueId = value;
      },
    },
  },
  watch: {
    passedPlayer: {
      deep: true,
      handler: function () {
        this.footballPlayer = new FootballPlayer(this.passedPlayer);
      },
    },
    playerLeague: {
      handler: function () {
        this.footballPlayer.footballLeagueId &&
          !this.leagueClubs.length &&
          this.$store.dispatch(
            "fetchPlayersByLeague",
            this.footballPlayer.footballLeagueId
          );
      },
    },
  },
  created() {
    this.footballPlayer = new FootballPlayer(this.passedPlayer);
  },
};
</script>
<style scoped>
.modal-container {
  padding: 4rem 3rem;
}

.player-modal-button {
  margin: 0.5rem 0.5rem;
}
</style>
